import React, { useEffect, useRef, useState } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { classNames } from 'primereact/utils';
import { Button } from "primereact/button";
import 'primeicons/primeicons.css';
import { Galleria } from 'primereact/galleria';
import 'primereact/resources/themes/saga-blue/theme.css'; /* veya istediğiniz bir tema */
import 'primereact/resources/primereact.min.css'; /* PrimeReact bileşenleri için genel CSS */
const App = () => {
  function titleCase(str) {
    if (str == null) {
      return ''
    }
    else {
      return str.toLocaleLowerCase('tr-TR').replace(/(?:^|\s|,|;|!|:|-|\.|\?)[a-z0-9ğçşüöı]/g, function (match) {
        return match.toLocaleUpperCase('tr-TR');
      });
    }
  }
  const imageItemTemplate = (item) => {
    return <img src={item} style={{ width: '100%', display: 'block' }} />;
  }

  const thumbnailTemplate = (item) => {
    return <img src={item} style={{ display: 'block' }} />;
  }
  const isFetched = useRef(false);

  const [imgSrc, setImgSrc] = useState('')
  const [images, setImages] = useState([])
  const [activeIndex, setActiveIndex] = useState(0);
  const galleria = useRef(null);
  const [orderData, setOrderData] = useState({
    invoiceNumber: 0,
    distributionDate: null,
    orderDate: null,
    receivedDate: null,
    deliveredDate: null,
    note: "",
    status: "TO_BE_RECEIVED",
    orderProducts: [],
    customer: {
      businessCustomerId: 0,
      name: "",
    },
    deliveredUser: {
      name: "",
    },
    subBusiness: {
      name: null,
      preferredName: "",
      phone: ''
    },
    contact: {
      phone: "",
      fullName: "",
    },
    deliveredAddress: {
      title: "",
      detail:
        "",
    },
  })
  //asdasd
  const searchParams = window.location.search;
  const keyValue = searchParams.match(/k=([^&]*)/);

  const rawKey = keyValue ? keyValue[1] : null;
  if (rawKey == null) {
    window.location.replace('https://www.ennettakip.com.tr', '_blank')
  }
  const fetchOrderImages = async (imageInfos) => {
    const imagePromises = imageInfos.map(async (element) => {
      const blobUrl = 'https://api.ennettakip.com.tr/api/v1/google/open/' + element + '/ORDER';

      const blobResponse = await fetch(blobUrl, {
        method: "GET"
      });

      const contentDisposition = blobResponse.headers.get(
        "Content-Disposition"
      );
      let fileName = "unknown_file";
      if (contentDisposition && contentDisposition.includes("filename=")) {
        fileName = contentDisposition
          .split("filename=")[1]
          .split(";")[0]
          .replace(/"/g, "");
      }

      const blob = await blobResponse.blob();
      const imgUrl = URL.createObjectURL(blob);

      return imgUrl
    });

    // Tüm sonuçları bekle
    const imagesReturn = await Promise.all(imagePromises);

    console.log(imagesReturn)
    setImages(imagesReturn);
  }



  useEffect(() => {
    const query = new URLSearchParams(window.location.search)
    const rawKey = query.get("k")
    if (!isFetched.current) {
      fetch('https://api.ennettakip.com.tr/api/v1/order/open?encryptedId=' + rawKey, {
        method: 'GET',
        headers: {
          accept: '*/*',
        },
      })
        .then(response => {
          if (!response.ok) {
            window.location.replace('https://www.ennettakip.com.tr', '_blank');
            return orderData;
          }
          return response.json();
        })
        .then(data => {
          setOrderData(data);
          fetchBlobAndConvertToFile(data.logoId);
          fetchOrderImages(data.images)

          isFetched.current = true; // Bu noktadan sonra tekrar istek atılmaz
        })
        .catch(error => {
          console.error('Error:', error);
        });
    }
  }, []);


  const formatDateTime = (dateString, showHours) => {
    const date = new Date(dateString);
    const days = [
      "Pazar",
      "Pazartesi",
      "Salı",
      "Çarşamba",
      "Perşembe",
      "Cuma",
      "Cumartesi"
    ];

    const months = [
      "Ocak",
      "Şubat",
      "Mart",
      "Nisan",
      "Mayıs",
      "Haziran",
      "Temmuz",
      "Ağustos",
      "Eylül",
      "Ekim",
      "Kasım",
      "Aralık"
    ];
    const dayName = days[date.getDay()];
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();

    const monthName = months[date.getMonth()];
    if (showHours == true) {
      return `${day} ${monthName} ${dayName} ${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}`;

    }
    else {
      return `${day} ${monthName} ${dayName}`;
    }
  };

  const [orderStatus, setOrderStatus] = useState({
    trackingNumber: "001698653lp",
    shippedVia: "UPS Ground",
    status: "IN_DISTRIBUTION",
    expectedDate: "26.11.2024",
    currentStatus: "RECEIVED",
    steps: [
      { title: "Sipariş Bekleniyor", icon: "pe-7s-clock", statusClass: "to-be-received" },
      { title: "Sipariş Alındı", icon: "pe-7s-cart", statusClass: "received" },
      { title: "Sipariş İşlemde", icon: "bi bi-gear", status: "RECEIVED", color: "#6ebe71" },
      { title: "Sipariş Dağıtımda", icon: "pe-7s-car", statusClass: "in-distribution" },
      { title: "Teslim Edildi", icon: "pe-7s-home", statusClass: "delivered" },
    ],
    notify: false,
  });

  const steps = [
    { id: 1, title: "Sipariş Oluşturuldu", icon: "pe-7s-clock", color: "#6ebe71" },
    { id: 2, title: "Sipariş Alındı", icon: "pe-7s-cart", status: "RECEIVED", color: "#6ebe71" },
    { id: 3, title: "Sipariş İşlemde", icon: "pe-7s-config", status: "RECEIVED", color: "#6ebe71" },
    { id: 4, title: "Sipariş Dağıtımda", icon: "pe-7s-car", status: "IN_DISTRIBUTION", color: "#6ebe71" },
    { id: 5, title: "Teslim Edildi", icon: "pe-7s-home", status: "DELIVERED", color: "#6ebe71" },
  ];

  const canceledSteps = [
    { id: 1, title: "Sipariş Oluşturuldu", icon: "pe-7s-clock", status: "TO_BE_RECEIVED", color: "#f2a654" },
    { id: 6, title: "Sipariş İptal Edildi", icon: "pe-7s-close-circle", status: "CANCELED", color: "#e74c3c" },
  ];
  const toggleNotify = () => {
    setOrderStatus((prevState) => ({
      ...prevState,
      notify: !prevState.notify,
    }));
  };
  const [width, setWidth] = useState(0)
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
    console.log(window.innerWidth <= 820)
  }
  useEffect(() => {
    setWidth(window.innerWidth);
    window.addEventListener('resize', handleWindowSizeChange);
    console.log(window.innerWidth)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);
  const statusLabel =
    orderData.status === 'TO_BE_RECEIVED' ? 'Sipariş Alınacak' :
      orderData.status === 'RECEIVED' ? 'Sipariş İşlemde' :
        orderData.status === 'WAITING' ? 'Sipariş Beklemede' :
          orderData.status === 'READY' ? 'Sipariş Hazır' :
            orderData.status === 'IN_DISTRIBUTION' ? 'Sipariş Dağıtımda' :
              orderData.status === 'DELIVERED' ? 'Sipariş Teslim Edildi' :
                orderData.status === 'CANCELED' ? 'Sipariş İptal Edildi' :
                  'Bilinmeyen Durum'; // Varsayılan değer
  const itemTemplate = (item, index) => {
    const { product, squareMeters, meters, width, weight, amount } = item;


    return (
      <div
        className="col-12"
        key={index}
        style={{
          borderWidth: 1,
          borderLeftWidth: 0,
          borderRightWidth: 0,
          borderStyle: 'solid',
          borderTopWidth: index === 0 ? 0 : 1,
          borderBottomWidth: 0,
          borderColor: '#9e9d9b',
        }}
      >
        <div
          className={classNames(
            'flex flex-column xl:flex-row xl:align-items-start p-2 gap-4',
            { 'border-top-1 surface-border': index !== 0 }
          )}
        >
          <div className="flex" style={{ display: 'flex', justifyContent: 'space-between' }}>
            {/* Sol tarafa yaslanmış etiket ikonu */}
            <div
              className="flex align-items-center"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '40px',
              }}
            >
              <i className="pi pi-tag" style={{ fontSize: '24px', color: '#555' }}></i>
            </div>

            {/* Ürün bilgileri */}
            <div className="flex flex-column align-items-center sm:align-items-start gap-3" style={{ flex: 1 }}>
              <div
                className="text-2xl font-bold text-900"
                style={{ fontWeight: 'bold', fontSize: 18 }}
              >
                {titleCase(product.name)}
              </div>
              <div className="flex align-items-center gap-3">
                <span className="flex align-items-center gap-2">
                  <span className="font-semibold"> {product.type === 'SQUARE_METERS'
                    ? `${parseFloat(squareMeters).toFixed(2)} M²`
                    : product.type === 'METERS'
                      ? `${parseFloat(meters).toFixed(2)} Metre`
                      : product.type === 'CM'
                        ? `${parseFloat(width).toFixed(2)} Cm`
                        : product.type === 'WEIGHT'
                          ? `${parseFloat(weight).toFixed(2)} Kilo`
                          : ''}</span>
                </span>
              </div>
            </div>

            {/* Sağ tarafa yaslanmış quantity */}
            <div
              className="flex justify-content-end"
              style={{ marginLeft: 'auto', fontSize: 16, marginTop: 15 }}
            >
              <span
                className="font-semibold mt-2"
                style={{ fontWeight: 'bold', marginTop: 20 }}
              >
                {amount} Adet
              </span>
            </div>
          </div>
        </div>
      </div>

    );
  };
  const formattedDate = orderData.distributionDate
    ? formatDateTime(orderData.distributionDate)
    : '';

  const formatOrderDate = orderData.orderDate
    ? formatDateTime(orderData.orderDate)
    : '';




  const formattedDeliverDate = orderData.deliveredDate == null ? '' : formatDateTime(orderData.deliveredDate, true)
  const formattedReceivedDate = orderData.receivedDate == null ? '' : formatDateTime(orderData.receivedDate, true)

  const listTemplate = (items) => {
    if (!items || items.length === 0) return null;

    const list = items.map((item, index) => itemTemplate(item, index));

    return <div className="grid grid-nogutter">{list}</div>;
  };

  const isMobile = width <= 820;
  const currentStatus = "shipping";

  const fetchBlobAndConvertToFile = async (id) => {
    const blobUrl = 'https://api.ennettakip.com.tr/api/v1/google/open/' + id + '/LOGO';
    const response = fetch(blobUrl,
      {
        method: 'GET'
      },
    ).then(res => {
      if (res.status == 200) {
        res.blob().then((resp) => {
          const imgUrl = URL.createObjectURL(resp);
          const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
          link.type = 'image/x-icon';
          link.rel = 'shortcut icon';
          link.href = imgUrl;
          console.log(imgUrl)
          setImgSrc(imgUrl)
        })
      }
      else {
        setImgSrc('/varsayilan-gorsel.jpg')
      }

    })
  }
  const formatliNumaraAyarla = (phone) => {
    // Telefon numarasını temizle
    const temizlenmisSayi = phone.replace(/^[+09]+|[^0-9]/g, '');

    // Başında "0" varsa kaldır
    const duzenlenmisSayi = temizlenmisSayi.startsWith("0")
      ? temizlenmisSayi.substr(1)
      : temizlenmisSayi;

    // Eğer uzunluk 10 ise geçerli, değilse null döndür
    return duzenlenmisSayi.length === 10 ? duzenlenmisSayi : null;
  };
  function TrFormatNumber(value) {
    if (value == undefined || value == null) {
      return '0.00 ₺'
    }
    else {
      return Intl.NumberFormat("tr", {
        minimumFractionDigits: 2, maximumFractionDigits: 2
      }).format(value) + ' ₺'
    }

  }
  //asd
  return (
    <div className="main_container" style={{ marginTop: 40, paddingBottom: "60px" }}>

      <div className="container padding-bottom-3x mb-2" >
        <div style={{ display: isMobile == false ? 'flex' : 'none', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginBottom: 30 }}>
          <div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <label style={{ fontSize: '20px' }}>Sayın, </label>
              <label style={{ fontSize: '20px', fontWeight: 'bold', marginLeft: 10, color: '#ed8815' }}> {titleCase(orderData.customer ? orderData.customer.name : '')} </label>
              <label style={{ fontSize: '20px', marginLeft: 10 }}> Siparişiniz İçin Teşekkürler </label>

            </div>
          </div>
          <img src={imgSrc} className="w-full" alt="Logo" style={{ height: 100, display: imgSrc == '' ? 'none' : '' }} />
        </div>
        <div style={{ display: isMobile == true ? '' : 'none', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginBottom: 30 }}>
          <img src={imgSrc} alt="Logo" className="w-full" style={{ height: '100%', display: imgSrc == '' ? 'none' : 'block', marginLeft: 'auto', marginRight: 'auto', maxWidth: '100%' }} />

          <div style={{ textAlign: 'center', marginBottom: 20 }}>
            <label style={{ fontSize: '18px' }}>Sayın, </label>
            <label style={{ fontSize: '20px', fontWeight: 'bold', marginLeft: 10, color: '#ed8815' }}> {titleCase(orderData.customer ? orderData.customer.name : '')}</label><br></br>
            <label style={{ fontSize: '18px' }}>Siparişiniz İçin Teşekkürler </label>

          </div>
        </div>

        <div className="card mb-3">
          <div className="d-flex flex-wrap flex-sm-nowrap justify-content-between py-3 px-2" style={{ backgroundColor: '#203A4B' }}>
            <div className="w-100 text-center py-1 px-2" style={{ color: '#fff', display: isMobile == true ? 'none' : '' }}>
              <span style={{ color: '#fff', fontWeight: 'bold', fontSize: 14 }} className="text-medium">Firma Telefonu:</span><a style={{ textDecoration: 'none', fontSize: 14 }} href={"tel:" + orderData.subBusiness.phone}> {orderData.subBusiness.phone}</a>
            </div>
            <div className="w-100 text-center py-1 px-2" style={{ color: '#fff' }}>
              <span style={{ color: '#fff', fontWeight: 'bold', fontSize: 14 }} className="text-medium">Sipariş Durum:</span> {statusLabel}
            </div>
            <div className="w-100 text-center py-1 px-2" style={{ color: '#fff' }}>
              <span style={{ color: '#fff', fontWeight: 'bold', fontSize: 14 }} className="text-medium">{orderData.status != 'TO_BE_RECEIVED' ? 'Alım Tarihi:' : 'Tahmini Alım:'}</span> {orderData.status != 'TO_BE_RECEIVED' ? formattedReceivedDate : formatOrderDate}
            </div>
            <div className="w-100 text-center py-1 px-2" style={{ color: '#fff' }}>
              <span style={{ color: '#fff', fontWeight: 'bold', fontSize: 14 }} className="text-medium">{orderData.status == 'DELIVERED' ? 'Teslim Tarihi:' : 'Tahmini Teslim:'}</span> {orderData.status == 'DELIVERED' ? formattedDeliverDate : formattedDate}
            </div>
          </div>
          <div className="card-body">
            <div className="steps d-flex  flex-sm-nowrap justify-content-between padding-top-2x padding-bottom-1x" style={{ marginTop: 30, display: orderData.status != 'CANCELED' ? 'flex' : 'none' }}>
              {
                orderData.status == 'CANCELED' ?
                  canceledSteps.map((step, index) => {
                    // İlk step her zaman tamamlanmış olacak
                    const isCompleted = index === 0 || (index === 2 && orderData.status == 'RECEIVED') || steps.findIndex((s) => s.status === orderData.status) >= index;
                    const stepStyle = isCompleted
                      ? { backgroundColor: step.color, borderColor: step.color, color: "#fff" }
                      : { backgroundColor: "#f5f5f5", borderColor: "#e1e7ec", color: "#ccc" };
                    return (
                      <div key={step.id} className={`step ${isCompleted ? "completed" : ""}`}>
                        <div className="step-icon-wrap">
                          <div className="step-icon" style={stepStyle}>
                            <i className={step.icon} style={{ width: 10, height: 10 }}></i>
                          </div>
                        </div>
                        <h4 className="step-title">{step.title}</h4>
                      </div>
                    );
                  })
                  :
                  steps.map((step, index) => {
                    // İlk step her zaman tamamlanmış olacak
                    const isCompleted = index === 0 || (index === 2 && orderData.status == 'RECEIVED') || steps.findIndex((s) => s.status === orderData.status) >= index;
                    const stepStyle = isCompleted
                      ? { backgroundColor: step.color, borderColor: step.color, color: "#fff" }
                      : { backgroundColor: "#f5f5f5", borderColor: "#e1e7ec", color: "#ccc" };

                    return (
                      <div key={step.id} className={`step ${isCompleted ? "completed" : ""}`}>
                        <div className="step-icon-wrap">
                          <div className="step-icon" style={stepStyle}>
                            <i className={step.icon}></i>
                          </div>
                        </div>
                        <h4 className="step-title">{step.title}</h4>
                      </div>
                    );
                  })}
            </div>


          </div>

        </div>
        <div style={{ display: 'flex', gap: '10px', flexDirection: isMobile ? 'column' : 'row' }}>
          <div className="card col-12 col-md-6" style={{ flex: '1' }}>
            <div className="d-flex flex-wrap flex-sm-nowrap justify-content-between py-3 px-2" style={{ backgroundColor: '#0CBDDE' }}>
              <div className="w-100 text-center py-1 px-2" style={{ color: '#fff' }}>
                <span style={{ color: '#fff', fontWeight: 'bold' }} className="text-medium">Sipariş Bilgileri</span>
              </div>

            </div>
            <div className="card-body">

              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 15 }}>
                <label style={{ fontSize: 18, fontWeight: 'bold' }}>Sipariş No</label>
                <label style={{ fontSize: 18, textAlign: 'right', marginLeft: 'auto' }}>#{orderData.invoiceNumber}</label>
              </div>
              <div style={{ width: '100%', height: 1, backgroundColor: '#9e9d9b' }}>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 15 }}>
                <label style={{ fontSize: 18, fontWeight: 'bold' }}>Müşteri No</label>
                <label style={{ fontSize: 18, textAlign: 'right', marginLeft: 'auto' }}>#{orderData.customer.businessCustomerId}</label>
              </div>
              <div style={{ width: '100%', height: 1, backgroundColor: '#9e9d9b' }}>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 15 }}>
                <label style={{ fontSize: 18, fontWeight: 'bold' }}>İletişim Numarası</label>
                <label style={{ fontSize: 18, textAlign: 'right', marginLeft: 'auto' }}>{orderData.contact.phone}</label>
              </div>

              <div style={{ width: '100%', height: 1, backgroundColor: '#9e9d9b' }}>
              </div>
              <div style={{ display: isMobile == true ? 'none' : 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 15 }}>
                <label style={{ fontSize: 18, fontWeight: 'bold', flex: 1 }}>Sipariş Adresi</label>
                <label style={{ fontSize: 16, flex: 1, textAlign: 'right' }}>{orderData.deliveredAddress.detail}</label>
              </div>
              <div style={{ display: isMobile == true ? '' : 'none', justifyContent: 'space-between', alignItems: 'center', padding: 15 }}>
                <label style={{ fontSize: 18, fontWeight: 'bold', flex: 1 }}>Sipariş Adresi</label>
                <label style={{ fontSize: 15, flex: 1, textAlign: 'left', width: '100%', marginTop: 10 }}>{orderData.deliveredAddress.detail}</label>
              </div>
              <div style={{ display: orderData.note == '' ? 'none' : orderData.note == null ? 'none' : '', width: '100%', height: 1, backgroundColor: '#9e9d9b' }}>
              </div>
              <div style={{ display: orderData.note == '' ? 'none' : orderData.note == null ? 'none' : 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 15 }}>
                <label style={{ fontSize: 18, fontWeight: 'bold' }}>Sipariş Notu</label>
                <label style={{ fontSize: 18, textAlign: 'right', marginLeft: 'auto' }}>{orderData.note}</label>
              </div>
              <div style={{ width: '100%', height: 1, backgroundColor: '#9e9d9b' }}>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 15 }}>
                <label style={{ fontSize: 18, fontWeight: 'bold' }}>İlgili Personel</label>
                <label style={{ fontSize: 18, textAlign: 'right', marginLeft: 'auto' }}>{orderData.status == 'TO_BE_RECEIVED' ? titleCase(orderData.receivingUser ? orderData.receivingUser.name : '') : titleCase(orderData.deliveredUser ? orderData.deliveredUser.name : '')}</label>
              </div>
            </div>
          </div>
          <div className="card col-12 col-md-6" style={{ flex: '1',display:orderData.orderProducts.length > 0 ? '' : 'none' }}>
            <div className="d-flex flex-wrap flex-sm-nowrap justify-content-between py-3 px-2" style={{ backgroundColor: '#67C5B5' }}>
              <div className="w-100 text-center py-1 px-2" style={{ color: '#fff' }}>
                <span style={{ color: '#fff', fontWeight: 'bold' }} className="text-medium">Ürün Bilgileri</span>
              </div>

            </div>
            <div className="card-body">
              <DataView value={orderData.orderProducts} listTemplate={listTemplate} />

            </div>
          </div>
          <div className="col-12 col-md-6" style={{ flex: '1',display:orderData.orderProducts.length > 0 ? 'none' : '' }}>
          </div>
        </div>



        <div style={{ display: 'flex', gap: '10px', flexDirection: isMobile ? 'column' : 'row', marginTop: 20 }}>
          <div className="card col-12 col-md-6" style={{ flex: '1', display: images.length > 0 ? '' : 'none', }}>
            <div className="d-flex flex-wrap flex-sm-nowrap justify-content-between py-3 px-2" style={{ backgroundColor: '#fcc513' }}>
              <div className="w-100 text-center py-1 px-2" style={{ color: '#fff' }}>
                <span style={{ color: '#fff', fontWeight: 'bold' }} className="text-medium">Sipariş Fotoğrafları</span>
              </div>

            </div>
            <div className="card-body">
              <div className="flex justify-content-center">
                <Galleria
                  ref={galleria}
                  value={images}
                  style={{ maxWidth: '850px' }}
                  activeIndex={activeIndex}
                  onItemChange={(e) => setActiveIndex(e.index)}
                  circular
                  fullScreen={true}
                  showItemNavigators
                  showThumbnails={false}
                  item={imageItemTemplate}
                  thumbnail={thumbnailTemplate}
                />

                <div
                  className="grid"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center', // Dikey ortalama
                    gap: '10px', // Resimler arasında boşluk
                  }}
                >
                  {images &&
                    images.map((image, index) => {
                      let imgEl = (
                        <img
                          src={image}
                          className="w-full"
                          style={{
                            height: 60,
                            cursor: 'pointer',
                            width: '50%',
                            objectFit: 'cover', // Resim boyutlandırma düzeni
                          }}
                          onClick={() => {
                            setActiveIndex(index);
                            galleria.current.show();
                          }}
                        />
                      );
                      return (
                        <div
                          className="col-4"
                          key={index}
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          {imgEl}
                        </div>
                      );
                    })}
                </div>
              </div>

            </div>
          </div>
          <div className="card col-12 col-md-6" style={{ flex: '1', display: orderData.status == 'DELIVERED' ? '' : orderData.status == 'IN_DISTRIBUTION' ? '' : 'none' }}>
            <div className="d-flex flex-wrap flex-sm-nowrap justify-content-between py-3 px-2" style={{ backgroundColor: '#F28243' }}>
              <div className="w-100 text-center py-1 px-2" style={{ color: '#fff' }}>
                <span style={{ color: '#fff', fontWeight: 'bold' }} className="text-medium">Ödeme Bilgileri</span>
              </div>

            </div>
            <div className="card-body">

              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 15 }}>
                <label style={{ fontSize: 18, fontWeight: 'bold' }}>İşlem Tutarı</label>
                <label style={{ fontSize: 18, textAlign: 'right', marginLeft: 'auto' }}>{orderData.discount == null ? TrFormatNumber(0 + orderData.finalAmount) : TrFormatNumber(orderData.discount + orderData.finalAmount)}</label>
              </div>
              <div style={{ width: '100%', height: 1, backgroundColor: '#9e9d9b' }}>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 15 }}>
                <label style={{ fontSize: 18, fontWeight: 'bold' }}>İndirim</label>
                <label style={{ fontSize: 18, textAlign: 'right', marginLeft: 'auto' }}>{TrFormatNumber(orderData.discount)}</label>
              </div>
              <div style={{ width: '100%', height: 1, backgroundColor: '#9e9d9b' }}>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 15 }}>
                <label style={{ fontSize: 18, fontWeight: 'bold' }}>Ara Toplam</label>
                <label style={{ fontSize: 18, textAlign: 'right', marginLeft: 'auto' }}>{TrFormatNumber(orderData.finalAmount)}</label>
              </div>
              <div style={{ width: '100%', height: 1, backgroundColor: '#9e9d9b' }}>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 15 }}>
                <label style={{ fontSize: 18, fontWeight: 'bold', flex: 1 }}>Ödenen Tutar</label>
                <label style={{ fontSize: 18, flex: 1, textAlign: 'right' }}>{TrFormatNumber(orderData.receivedAmount)}</label>
              </div>

              <div style={{ width: '100%', height: 1, backgroundColor: '#9e9d9b' }}>
              </div>

              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 15 }}>
                <label style={{ fontSize: 18, fontWeight: 'bold', flex: 1 }}>Kalan Tutar</label>
                <label style={{ fontSize: 18, flex: 1, textAlign: 'right' }}>{TrFormatNumber(orderData.finalAmount - (orderData.receivedAmount == null ? 0 : orderData.receivedAmount))}</label>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6" style={{ flex: '1', display: images.length > 0 ? 'none' : '', }}>
          </div>
        </div>

      </div>
      <div className="footer grid">
        <Button label="Whatsapp" icon='pi pi-whatsapp' className="col-6" style={{ height: 50, backgroundColor: '#25d366', fontSize: 20, fontWeight: 'bold', color: '#FFF', borderWidth: 0, display: isMobile != true ? 'none' : '', paddingLeft: 25, paddingRight: 35 }} onClick={() => {
          window.location.replace(`https://wa.me/90${formatliNumaraAyarla(orderData.subBusiness.whatsappPhone)}?text=Merhaba`, '_blank')
        }}>

        </Button>
        <Button label="Şimdi Ara" icon='pi pi-phone' className="col-6" style={{ height: 50, backgroundColor: '#007BFF', fontSize: 20, fontWeight: 'bold', color: '#FFF', borderWidth: 0, display: isMobile != true ? 'none' : '', paddingLeft: 25, paddingRight: 35 }} onClick={() => {
          window.location.replace(`tel:+90${formatliNumaraAyarla(orderData.subBusiness.phone)}`, '_blank')
        }}>

        </Button>
      </div>
      <div>

      </div>
    </div>
  );
};

export default App;
